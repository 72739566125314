import { graphql, useStaticQuery } from 'gatsby';

import { AllDirectusBankingAd, BankingAd } from './banking-ads.interface';

export default (): BankingAd[] => {
  const { allDirectusBankingAd } = useStaticQuery<AllDirectusBankingAd>(graphql`
    query {
      allDirectusBankingAd(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            bank_name
            brand_colour
            content
            logo {
              data {
                full_url
              }
              localFile {
                childImageSharp {
                  fixed(width: 58, height: 58) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                  }
                }
              }
            }
            logo_alt_text
            background_image {
              data {
                full_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 768, maxHeight: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return allDirectusBankingAd.edges;
};
