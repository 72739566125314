import React from 'react';
import { Props } from './Hero.interface';

import { hero, overlay } from './Hero.scss';

export default ({ children }: Props): JSX.Element => (
  <div className={hero} id="home-top">
    <div className={overlay} />
    {children}
  </div>
);
