import React from 'react';
import { Helmet } from 'react-helmet';
import companyDetailsHook from 'hooks/company-details/company-details.hook';
import trustpilotBannerHook from 'hooks/trustpilot-banner/trustpilot-banner.hook';

const CompanyRichSnippet = (): JSX.Element => {
  const company = companyDetailsHook()[0];
  const { review } = trustpilotBannerHook();

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: company.node.company_name,
          url: company.node.url,
          logo: 'https://d2zkzcdiu38fde.cloudfront.net/images/d17b483e-8c5c-44d9-9dee-72a4c63cc7b8.png',
          sameAs: [
            'https://www.facebook.com/rapidformations/',
            'https://twitter.com/RapidUKOfficial',
            'https://www.linkedin.com/company/rapid-formations/',
          ],
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+44-20-7871-9990',
              contactType: 'customer support',
              areaServed: 'GB',
              availableLanguage: ['English'],
            },
          ],
          address: {
            '@type': 'PostalAddress',
            streetAddress: company.node.company_address,
            addressLocality: company.node.company_address_locality,
            postalCode: company.node.company_postcode,
            addressCountry: company.node.company_country,
          },
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: review.score,
            reviewCount: parseInt(review.total.replace(/,/g, ''), 10),
          },
          telephone: company.node.telephone_number,
          email: company.node.email_address,
          VatID: company.node.company_vat,
        })}
      </script>
    </Helmet>
  );
};

export default CompanyRichSnippet;
