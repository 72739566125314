import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Markdown from 'components/common/markdown/Markdown';
import { Link } from 'components/common/link/Link';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import registeredOfficesHook from 'hooks/our-registered-offices/our-registered-offices.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';

import {
  ourRegisteredOffices,
  ourRegisteredOffices__offices,
  ourRegisteredOffices__office,
  ourRegisteredOffices__heading,
  ourRegisteredOffices__price,
  ourRegisteredOffices__content,
} from './RegisteredOffices.scss';

export default (): JSX.Element => {
  const offices = registeredOfficesHook();
  const { node: section } = getFieldBySlug('registered-offices', sectionTitleHook());

  return (
    <div className={ourRegisteredOffices}>
      <SectionTitle title={section.heading} align="center" />
      <div className={ourRegisteredOffices__offices}>
        {offices.map(({ node }) => (
          <div key={`office-${slugify(node.heading)}`} className={ourRegisteredOffices__office}>
            <Img fluid={node.image.localFile.childImageSharp.fluid} fadeIn />
            <Link to={node.button_link}>
              <h3 className={ourRegisteredOffices__heading}>
                <Markdown source={node.heading} />
              </h3>
              <p className={ourRegisteredOffices__price}>
                <PriceWithoutVat price={node.price.value} /> {node.term}
              </p>
            </Link>
            <Markdown className={ourRegisteredOffices__content} source={node.content} container />
            <ButtonGroup block>
              <Button role="secondary" size="large" to={node.button_link}>
                {node.button_text}
              </Button>
            </ButtonGroup>
          </div>
        ))}
      </div>
    </div>
  );
};
