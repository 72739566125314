import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Img from 'gatsby-image';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Markdown from 'components/common/markdown/Markdown';
import Modal from 'components/common/modal/Modal';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import bankingAdsHook from 'hooks/banking-ads/banking-ads.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import BackgroundImageLazy from 'components/common/lazy-loading/background-image/BackgroundImage';
import { Props, Bank } from './BankAdsGrid.interface';

import {
  bankAdsGrid__gridItem,
  bankAdsGrid__gridItem_logo,
  bankAdsGrid__gridItem_title,
  bankAdsGrid,
  bankAdsGrid__cta,
  bankAdsGrid__gridItem___Barclays,
  bankAdsGrid__gridItem___Mettle,
  bankAdsGrid__gridItem___TSB,
  bankAdsGrid__gridItem___Cashplus,
  bankAdsGrid__gridItem___Countingup,
  bankAdsGrid__gridItem___Tide,
  bankAdsGrid__gridItem___CardOne,
  bankAdsGrid__gridItem___Anna,
} from './BankAdsGrid.scss';

const getBankClass = (bank: string): string => {
  switch (bank) {
    case 'Barclays':
      return bankAdsGrid__gridItem___Barclays;
    case 'Mettle':
      return bankAdsGrid__gridItem___Mettle;
    case 'TSB':
      return bankAdsGrid__gridItem___TSB;
    case 'Cashplus':
      return bankAdsGrid__gridItem___Cashplus;
    case 'Countingup':
      return bankAdsGrid__gridItem___Countingup;
    case 'Tide':
      return bankAdsGrid__gridItem___Tide;
    case 'CardOne':
      return bankAdsGrid__gridItem___CardOne;
    case 'Anna':
      return bankAdsGrid__gridItem___Anna;
    default:
      return '';
  }
};

export default ({ isHome = false }: Props): JSX.Element => {
  const slug = 'home-bank-ads';
  const { node } = getFieldBySlug(slug, sectionTitleHook());
  const banks = bankingAdsHook();

  const BankGridItem = ({ content, logo, bank_name, brand_color, logo_alt, background_image }: Bank) => (
    <Modal
      trigger={
        <BackgroundImageLazy
          classes={`${bankAdsGrid__gridItem} ${getBankClass(bank_name)}`}
          styles={{
            backgroundColor: brand_color,
            backgroundPosition: `${Math.floor(Math.random() * (90 - 10)) + 10}% -${
              Math.floor(Math.random() * (390 - 100 + 1)) + 100
            }px`,
          }}
          imageUrl={background_image.localFile.childImageSharp.fluid.srcWebp}
        >
          <i className={bankAdsGrid__gridItem_logo}>
            <Img fixed={logo.localFile.childImageSharp.fixed} alt={logo_alt} />
          </i>
          <h3 className={bankAdsGrid__gridItem_title}>{bank_name}</h3>
        </BackgroundImageLazy>
      }
      lazy
    >
      <Markdown source={content} container />
    </Modal>
  );

  return (
    <>
      <SectionTitle title={node.heading} subtitle={node.subheading} align="center" />
      <div className={bankAdsGrid}>
        {banks.map(({ node }) => {
          const { bank_name, brand_colour, logo, logo_alt_text, content, background_image } = node;
          return (
            <div key={bank_name}>
              <BankGridItem
                bank_name={bank_name}
                brand_color={brand_colour}
                logo={logo}
                background_image={background_image}
                logo_alt={logo_alt_text}
                content={content}
              />
            </div>
          );
        })}
      </div>
      {isHome && (
        <ButtonGroup block className={bankAdsGrid__cta}>
          <Button role="primary" size="large" onClick={() => scrollTo('#home-top')}>
            Get Started
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};
