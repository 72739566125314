// extracted by mini-css-extract-plugin
export var bankAdsGrid = "BankAdsGrid--bankAdsGrid--AHRQP";
export var bankAdsGrid__cta = "BankAdsGrid--bankAdsGrid__cta--Ktn-e";
export var bankAdsGrid__gridItem = "BankAdsGrid--bankAdsGrid__gridItem--TX4bs";
export var bankAdsGrid__gridItem___Anna = "BankAdsGrid--bankAdsGrid__gridItem___Anna--Lzcp8";
export var bankAdsGrid__gridItem___Barclays = "BankAdsGrid--bankAdsGrid__gridItem___Barclays--o2HR3";
export var bankAdsGrid__gridItem___CardOne = "BankAdsGrid--bankAdsGrid__gridItem___CardOne--4e55e";
export var bankAdsGrid__gridItem___Cashplus = "BankAdsGrid--bankAdsGrid__gridItem___Cashplus--Sy01+";
export var bankAdsGrid__gridItem___Countingup = "BankAdsGrid--bankAdsGrid__gridItem___Countingup--OkRLV";
export var bankAdsGrid__gridItem___Mettle = "BankAdsGrid--bankAdsGrid__gridItem___Mettle--+H8VH";
export var bankAdsGrid__gridItem___TSB = "BankAdsGrid--bankAdsGrid__gridItem___TSB--AKr5D";
export var bankAdsGrid__gridItem___Tide = "BankAdsGrid--bankAdsGrid__gridItem___Tide--KQ2KG";
export var bankAdsGrid__gridItem__tooltip = "BankAdsGrid--bankAdsGrid__gridItem__tooltip--7acYy";
export var bankAdsGrid__gridItem__tooltipBox = "BankAdsGrid--bankAdsGrid__gridItem__tooltipBox--sEjEb";
export var bankAdsGrid__gridItem_logo = "BankAdsGrid--bankAdsGrid__gridItem_logo--zSZi5";
export var bankAdsGrid__gridItem_title = "BankAdsGrid--bankAdsGrid__gridItem_title--PnbR9";
export var tkMyriadProSemiCondensed = "BankAdsGrid--tk-myriad-pro-semi-condensed--PwfnF";