import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusWhyChooseUs, WhyChooseUsEdges } from './why-choose-us.interface';

export default (): WhyChooseUsEdges[] => {
  const { allDirectusWhyChooseUs } = useStaticQuery<AllDirectusWhyChooseUs>(graphql`
    query {
      allDirectusWhyChooseUs {
        edges {
          node {
            title
            body
            image {
              data {
                full_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 635, maxHeight: 527, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            directusId
          }
        }
      }
    }
  `);

  return allDirectusWhyChooseUs.edges;
};
