import { graphql, useStaticQuery } from 'gatsby';

import { AllDirectusHero, Hero } from './hero.interface';

export default (): Hero[] => {
  const { allDirectusHero } = useStaticQuery<AllDirectusHero>(graphql`
    query {
      allDirectusHero {
        edges {
          node {
            directusId
            slug
            heading
            subheading
            model_image {
              data {
                full_url
              }
              width
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            model_image_alt
            model_image_safari {
              data {
                full_url
              }
              width
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            usps {
              directusId
              slug
              title
              content
              image {
                data {
                  full_url
                }
                width
                height
                localFile {
                  childImageSharp {
                    fixed(width: 90, height: 90) {
                      ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                  }
                }
              }
              image_alt
              link_url
            }
            hero_boxes {
              directusId
              sort
              slug
              review {
                directusId
                slug
                provider
                score
                max_score
                total_reviews
                logo {
                  data {
                    full_url
                  }
                  localFile {
                    childImageSharp {
                      fixed(toFormat: WEBP, height: 60) {
                        ...GatsbyImageSharpFixed_withWebp_noBase64
                      }
                    }
                  }
                }
                colour
                logo_width
                link
                herobox_link
              }
            }
          }
        }
      }
    }
  `);

  return allDirectusHero.edges;
};
