import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusOurRegisteredOffice, RegisteredOffice } from './our-registered-offices.interface';

export default (): RegisteredOffice[] => {
  const { allDirectusOurRegisteredOffice } = useStaticQuery<AllDirectusOurRegisteredOffice>(graphql`
    query {
      allDirectusOurRegisteredOffice {
        edges {
          node {
            directusId
            sort
            heading
            content
            price {
              value
            }
            image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid(maxWidth: 830) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            term
            button_text
            button_link
          }
        }
      }
    }
  `);

  return allDirectusOurRegisteredOffice.edges;
};
