// extracted by mini-css-extract-plugin
export var heroBoxes = "HeroBoxes--heroBoxes--yqTS5";
export var heroBoxes__bottom = "HeroBoxes--heroBoxes__bottom--txbmo";
export var heroBoxes__box = "HeroBoxes--heroBoxes__box--CazdI";
export var heroBoxes__boxes = "HeroBoxes--heroBoxes__boxes--YGvNV";
export var heroBoxes__link = "HeroBoxes--heroBoxes__link--nykAB";
export var heroBoxes__logo = "HeroBoxes--heroBoxes__logo--8p3wi";
export var heroBoxes__rating = "HeroBoxes--heroBoxes__rating--tZiq6";
export var heroBoxes__reviews = "HeroBoxes--heroBoxes__reviews--u7nvH";
export var heroBoxes__stars = "HeroBoxes--heroBoxes__stars--Lu5lV";
export var heroBoxes__top = "HeroBoxes--heroBoxes__top--N6KLd";
export var tkMyriadProSemiCondensed = "HeroBoxes--tk-myriad-pro-semi-condensed--xYHhU";