import React from 'react';
import slugify from 'react-slugify';

import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import CollapsibleList from 'components/directus/collapsible-list/CollapsibleList';
import FAQPageRichSnippet from 'components/common/rich-snippets/faq-page/FaqPage';
import faqPanelHook from 'hooks/faq-panel/faq-panel.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug, sort } from 'utils/directus';
import { Props } from './HomepageFaqs.interface';

import { homepageFaqs, panel } from './HomepageFaqs.scss';

export default ({ slug }: Props): JSX.Element => {
  const questions = sort(faqPanelHook().filter(({ node }) => node.slug === slug));
  const { node } = getFieldBySlug(slug === 'home' ? 'home-faqs' : 'scottish-faqs', sectionTitleHook());
  const faqs = questions.map(({ node }) => ({
    question: node.title,
    answer: node.body,
  }));

  return (
    <>
      <div className={homepageFaqs}>
        <SectionTitle title={node.heading} align="center" />

        {questions.map(
          ({ node }): JSX.Element => (
            <div className={panel} key={`homepage-faq-panel-${node.directusId}`}>
              <CollapsibleList key={`homepage-faq-${slugify(node.title)}`} openText={node.title} closeText={node.title}>
                <Markdown source={node.body} container />
              </CollapsibleList>
            </div>
          )
        )}
      </div>
      <FAQPageRichSnippet faqs={faqs} />
    </>
  );
};
