import { graphql, useStaticQuery } from 'gatsby';
import { DirectusFormationVideo, FormationVideo } from './formation-video.interface';

export default (): FormationVideo => {
  const { directusFormationVideo } = useStaticQuery<DirectusFormationVideo>(graphql`
    query {
      directusFormationVideo {
        featured_image {
          data {
            full_url
          }
          width
          height
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        vimeo_link
      }
    }
  `);

  return directusFormationVideo;
};
