import React, { useRef, useEffect, useState } from 'react';

import { BackgroundImage, LazyBackgroundProps } from './BackgroundImage.interface';

const imageInView = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
  entries.forEach((entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      const target = entry.target as HTMLElement;
      const backgroundSrc = target.getAttribute('data-image');

      target.removeAttribute('data-image');
      target.style.backgroundImage = `url(${backgroundSrc})`;
      observer.unobserve(target);
    }
  });
};

const LazyBackground = ({ observer, classes, styles, imageUrl, children }: LazyBackgroundProps) => {
  const backgroundImage = useRef(null);

  useEffect(() => {
    const { current } = backgroundImage;

    if (observer !== null) {
      observer.observe(current);
    }

    return () => {
      if (observer !== null) {
        observer.unobserve(current);
      }
    };
  }, [observer]);

  return (
    <div className={classes} style={styles} ref={backgroundImage} data-image={imageUrl}>
      {children}
    </div>
  );
};

const LazyLoader = ({ children, classes, styles, imageUrl }: BackgroundImage) => {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(imageInView, { threshold: 0.5 });
    setObserver(observer);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <LazyBackground classes={classes} styles={styles} imageUrl={imageUrl} observer={observer}>
      {children}
    </LazyBackground>
  );
};

export default LazyLoader;
