import React from 'react';

import { Link } from 'components/common/link/Link';
import Markdown from 'components/common/markdown/Markdown';
import Carousel from 'components/directus/carousel/Carousel';
import heroHook from 'hooks/hero/hero.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props, CarouselProps } from './UniqueSellingPoints.interface';

import {
  uniqueSellingPoints,
  uniqueSellingPoints___desktop,
  uniqueSellingPoints___mobile,
  uniqueSellingPoints__item,
  uniqueSellingPoints__item___nonLink,
  uniqueSellingPoints__link,
  uniqueSellingPoints__image,
  uniqueSellingPoints__title,
  uniqueSellingPoints__body,
} from './UniqueSellingPoints.scss';

export default ({ slug }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, heroHook());

  const UniqueSellingPoint = ({ title, image, imageAlt, body, linkUrl }: CarouselProps): JSX.Element => {
    if (!linkUrl) {
      return (
        <div className={`${uniqueSellingPoints__item} ${uniqueSellingPoints__item___nonLink}`}>
          <img
            src={image.data.full_url}
            alt={imageAlt}
            className={uniqueSellingPoints__image}
            style={{ width: '90px', height: '90px' }}
            loading="eager"
          />
          <h2 className={uniqueSellingPoints__title}>{title}</h2>
          <Markdown className={uniqueSellingPoints__body} source={body} container />
        </div>
      );
    }

    return (
      <div className={uniqueSellingPoints__item}>
        <Link to={linkUrl} className={uniqueSellingPoints__link}>
          <img
            src={image.data.full_url}
            alt={imageAlt}
            className={uniqueSellingPoints__image}
            style={{ width: '90px', height: '90px' }}
            loading="eager"
          />
          <h3 className={uniqueSellingPoints__title}>{title}</h3>
          <Markdown className={uniqueSellingPoints__body} source={body} container />
        </Link>
      </div>
    );
  };

  return (
    <div className={uniqueSellingPoints}>
      <div className={uniqueSellingPoints___desktop}>
        {node.usps.map((usp) => (
          <UniqueSellingPoint
            key={`usp-${usp.slug}`}
            title={usp.title}
            image={usp.image}
            imageAlt={usp.image_alt}
            body={usp.content}
            linkUrl={usp.link_url}
          />
        ))}
      </div>
      <div className={uniqueSellingPoints___mobile}>
        <Carousel width={300} height={225} maxVisibleSlides={1}>
          {node.usps.map((usp) => (
            <UniqueSellingPoint
              key={`usp-${usp.slug}`}
              title={usp.title}
              image={usp.image}
              imageAlt={usp.image_alt}
              body={usp.content}
              linkUrl={usp.link_url}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};
