import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import StarRatings from 'react-star-ratings';

import facebookLogo from 'assets/images/facebook-logo.png';
import googleLogo from 'assets/images/google-logo.png';
import trustpilotLogo from 'assets/images/trustpilot-logo.png';
import heroHook from 'hooks/hero/hero.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { checkFloat } from 'utils/check-float';
import { Props } from './HeroBoxes.interface';

import {
  heroBoxes,
  heroBoxes__boxes,
  heroBoxes__box,
  heroBoxes__top,
  heroBoxes__logo,
  heroBoxes__stars,
  heroBoxes__bottom,
  heroBoxes__reviews,
  heroBoxes__link,
} from './HeroBoxes.scss';

export default ({ slug }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, heroHook());

  const getReadableSlug = (slug: string): string => {
    switch (slug) {
      case 'facebook':
        return 'Facebook';
      case 'trustpilot':
        return 'Trust Pilot';
      case 'google':
        return 'Google';
      default:
        return '';
    }
  };

  const images = {
    facebook: {
      image: facebookLogo,
      width: 101,
      height: 20,
    },
    google: {
      image: googleLogo,
      width: 91,
      height: 30,
    },
    trustpilot: {
      image: trustpilotLogo,
      width: 120,
      height: 30,
    },
  } as any;

  return (
    <div className={heroBoxes}>
      <Grid>
        <Row>
          <Col xs={12} xl={11}>
            <h3>Trusted by thousands of business owners</h3>
            <div className={heroBoxes__boxes}>
              {node.hero_boxes.map((box) => (
                <div key={box.slug} className={heroBoxes__box}>
                  <div className={heroBoxes__top}>
                    <img
                      className={heroBoxes__logo}
                      src={images[box.review.slug].image}
                      alt={`${box.review.provider} logo.`}
                      width={images[box.review.slug].width}
                      height={images[box.review.slug].height}
                      style={{
                        width: `${images[box.review.slug].width}px`,
                        height: `${images[box.review.slug].height}px`,
                      }}
                      loading="eager"
                    />
                    <StarRatings
                      className={heroBoxes__stars}
                      rating={Number(box.review.score)}
                      numberOfStars={5}
                      starRatedColor="#f39c12"
                      starEmptyColor="#c5c5c5"
                      starDimension="27px"
                      starSpacing="0"
                      name="rating"
                    />
                  </div>
                  <div className={heroBoxes__bottom}>
                    <div className={heroBoxes__reviews}>
                      {parseInt(box.review.total_reviews, 10).toLocaleString()} reviews
                    </div>
                    <div>
                      Rated {checkFloat(box.review.score) ? `${box.review.score}.0` : box.review.score} out of &nbsp;
                      {checkFloat(box.review.max_score) ? `${box.review.max_score}.0` : box.review.max_score}
                    </div>
                  </div>
                  <a
                    href={box.review.link}
                    target="_blank"
                    className={heroBoxes__link}
                    rel="noreferrer"
                    aria-label={`Click here to see Rapid Formation reviews on ${getReadableSlug(box.review.slug)}`}
                  >
                    &nbsp;
                  </a>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
