import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusPackage, PackageEdges } from './package-grid.interface';

export default (): PackageEdges[] => {
  const { allDirectusPackage } = useStaticQuery<AllDirectusPackage>(graphql`
    query {
      allDirectusPackage(filter: { type: { eq: "Limited Company" } }, sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            slug
            name
            description
            price {
              value
            }
            discounted_price {
              value
            }
            namecheck_path
            checkout_path
          }
        }
      }
    }
  `);

  return allDirectusPackage.edges;
};
