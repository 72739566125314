import React from 'react';

import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLightFontAwesomeIcon } from 'utils/font-awesome/light';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';

import WhyUseAgentHook from 'hooks/why-use-agent/why-use-agent';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './WhyUseAgent.interface';

import {
  margin as marginClass,
  whyUseAgent,
  whyUseAgent__grid,
  whyUseAgent__icon,
  whyUseAgent__item,
  whyUseAgent__title,
  whyUseAgent__subtitle,
  whyUseAgent__cta,
} from './WhyUseAgent.scss';

export default ({ margin }: Props): JSX.Element => {
  const { node } = getFieldBySlug('why-choose-agent', sectionTitleHook());
  const allDirectusWhyUseAgent = WhyUseAgentHook();

  return (
    <div className={margin ? marginClass : ''}>
      <div className={whyUseAgent}>
        <SectionTitle title={node.heading} subtitle={node.subheading} align="center" />

        <Grid fluid className={whyUseAgent__grid}>
          <Row>
            {allDirectusWhyUseAgent.map(({ node }) => (
              <Col xs={12} sm={6} md={4} lg={4} key={node.directusId}>
                <div className={whyUseAgent__item}>
                  <div className={whyUseAgent__icon}>
                    <FontAwesomeIcon icon={getLightFontAwesomeIcon(node.icon)} />
                  </div>
                  <h3 className={whyUseAgent__title}>{node.title}</h3>
                  <h4 className={whyUseAgent__subtitle}>{node.subtitle}</h4>
                </div>
              </Col>
            ))}
          </Row>
        </Grid>
        <ButtonGroup block className={whyUseAgent__cta}>
          <Button role="primary" size="large" onClick={() => scrollTo('#home-top')}>
            Get Started
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
