import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import nonResidentsOfferHook from 'hooks/non-residents-offer/non-residents-offer';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './NonResidentsOffer.interface';

import {
  margin as marginClass,
  nonUkOffer,
  nonUkOffer__panel,
  nonUkOffer__text,
  nonUkOffer__image,
  nonUkOffer__link,
} from './NonResidentsOffer.scss';

export default ({ margin }: Props): JSX.Element => {
  const { node } = getFieldBySlug('non-residents-offer', sectionTitleHook());
  const allDirectusNonResidentsOffer = nonResidentsOfferHook();

  return (
    <div className={margin ? marginClass : ''}>
      <div className={nonUkOffer}>
        <SectionTitle title={node.heading} align="center" />

        {allDirectusNonResidentsOffer.map(({ node }) => (
          <div key={node.slug} className={nonUkOffer__panel}>
            <div className={nonUkOffer__text}>
              <h4>{node.subtitle}</h4>
              <Markdown source={node.body} container />
              <Button to="/compare-packages/non-residents/" role="secondary" size="large" className={nonUkOffer__link}>
                {node.button_text}
              </Button>
            </div>

            <BackgroundImage className={nonUkOffer__image} fluid={node.image.localFile.childImageSharp.fluid} fadeIn />
          </div>
        ))}
      </div>
    </div>
  );
};
