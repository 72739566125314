import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusWhyUseAgent, WhyUseAgentEdges } from './why-use-agent.interface';

export default (): WhyUseAgentEdges[] => {
  const { allDirectusWhyUseAgent } = useStaticQuery<AllDirectusWhyUseAgent>(graphql`
    query {
      allDirectusWhyUseAgent: allDirectusWhyUseUs(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            sort
            icon
            title
            subtitle
            slug
          }
        }
      }
    }
  `);

  return allDirectusWhyUseAgent.edges;
};
