import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import { Link } from 'components/common/link/Link';
import Markdown from 'components/common/markdown/Markdown';
import PriceWithoutVat from 'components/common/price-without-vat/PriceWithoutVat';
import packageGridHook from 'hooks/package-grid/package-grid.hook';
import { url } from 'utils/url';

import {
  packageGrid,
  packageGrid__package,
  packageGrid__header,
  packageGrid__name,
  packageGrid__prices,
  packageGrid__price,
  packageGrid__price___discounted,
  packageGrid__description,
  packageGrid__buyButton,
  packageGrid__detailsButton,
  packageGrid__specialOffer,
} from './PackageGrid.scss';

export default (): JSX.Element => {
  const packages = packageGridHook();
  const [cookies] = useCookies();
  const [companyName, setCompanyName] = useState<string>('');

  useEffect(() => {
    setCompanyName(cookies['company-name']);
  }, [cookies['company-name']]);

  return (
    <div className={packageGrid}>
      {packages.map(({ node }) => (
        <div key={`package-grid-package-${node.slug}}`} className={packageGrid__package}>
          <div className={packageGrid__header}>
            <h3 className={packageGrid__name}>
              <Link to={`/package/${node.slug}/`}>{node.name}</Link>
            </h3>
            <div className={packageGrid__prices}>
              {node.discounted_price ? (
                <>
                  <span className={packageGrid__price}>
                    <PriceWithoutVat price={node.discounted_price.value} />
                  </span>
                  <span className={packageGrid__price___discounted}>
                    <span>
                      <PriceWithoutVat price={node.price.value} />
                    </span>
                    <span>40% OFF</span>
                  </span>
                </>
              ) : (
                <span className={packageGrid__price}>
                  <PriceWithoutVat price={node.price.value} />
                </span>
              )}
            </div>
          </div>
          <Markdown className={packageGrid__description} source={node.description} container />
          <ButtonGroup block column>
            {companyName !== '' && typeof companyName !== 'undefined' ? (
              <>
                <Button
                  to={url.account(`${node.checkout_path}?name=${encodeURIComponent(companyName)}&gle=namecheck`)}
                  className={packageGrid__buyButton}
                  aria-label={`Buy our ${node.name} package now.`}
                  role="primary"
                >
                  Buy Now
                </Button>
              </>
            ) : (
              <Button
                to={node.namecheck_path}
                className={packageGrid__buyButton}
                aria-label={`Buy our ${node.name} package now.`}
                role="primary"
              >
                Buy Now
              </Button>
            )}
            <Button role="secondary" to={`/package/${node.slug}/`} className={packageGrid__detailsButton} block>
              Read More
            </Button>
          </ButtonGroup>
          {node.discounted_price && (
            <div className={packageGrid__specialOffer}>
              <span>Special Offer</span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
