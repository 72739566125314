import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusNonResidentsOffer, NonResidentsOfferEdges } from './non-residents-offer.interface';

export default (): NonResidentsOfferEdges[] => {
  const { allDirectusNonResidentsOffer } = useStaticQuery<AllDirectusNonResidentsOffer>(graphql`
    query {
      allDirectusNonResidentsOffer {
        edges {
          node {
            id
            slug
            title
            subtitle
            body
            button_text
            image {
              data {
                full_url
              }
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return allDirectusNonResidentsOffer.edges;
};
