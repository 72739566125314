// extracted by mini-css-extract-plugin
export var tkMyriadProSemiCondensed = "UniqueSellingPoints--tk-myriad-pro-semi-condensed--URiXN";
export var uniqueSellingPoints = "UniqueSellingPoints--uniqueSellingPoints--Jo7-q";
export var uniqueSellingPoints___desktop = "UniqueSellingPoints--uniqueSellingPoints___desktop--69Pns";
export var uniqueSellingPoints___mobile = "UniqueSellingPoints--uniqueSellingPoints___mobile--gpNTX";
export var uniqueSellingPoints__body = "UniqueSellingPoints--uniqueSellingPoints__body--6p1yy";
export var uniqueSellingPoints__image = "UniqueSellingPoints--uniqueSellingPoints__image--IBCWo";
export var uniqueSellingPoints__item = "UniqueSellingPoints--uniqueSellingPoints__item--POLeY";
export var uniqueSellingPoints__item___nonLink = "UniqueSellingPoints--uniqueSellingPoints__item___nonLink--ZVoYa";
export var uniqueSellingPoints__link = "UniqueSellingPoints--uniqueSellingPoints__link--rWZbG";
export var uniqueSellingPoints__title = "UniqueSellingPoints--uniqueSellingPoints__title--tIsmT";