import React from 'react';
import slugify from 'react-slugify';

import Carousel from 'components/directus/carousel/Carousel';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import RatingStars from 'components/directus/rating-stars/RatingStars';
import Markdown from 'components/common/markdown/Markdown';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import recentReviewsHook from 'hooks/recent-reviews/recent-reviews.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './RecentReviews.interface';

import {
  reviewPane,
  reviewPane__review,
  reviewPane__stars,
  reviewPane__details,
  reviewPane__customer,
  reviewPane__company,
} from './RecentReviews.scss';

const ReviewPane = ({ customer_name, company_name, review, review_date, review_platform, logo }: Props) => (
  <div className={reviewPane}>
    <div className={reviewPane__review}>
      <p>
        <Markdown source={review} /> <span>{review_date}</span>
      </p>
    </div>
    <div className={reviewPane__stars}>
      <RatingStars score={5} />
    </div>
    <div className={reviewPane__details}>
      <div>
        <span className={reviewPane__customer}>{customer_name}</span>
        <span className={reviewPane__company}>{company_name}</span>
      </div>
      <img
        src={logo.data.full_url}
        alt={`${review_platform} logo.`}
        width={Math.ceil(logo.width * 0.6)}
        height={Math.ceil(logo.height * 0.6)}
        style={{ width: Math.ceil(logo.width * 0.6), height: Math.ceil(logo.height * 0.6) }}
        loading="lazy"
      />
    </div>
  </div>
);

export default (): JSX.Element => {
  const { node: section } = getFieldBySlug('recent-reviews', sectionTitleHook());
  const allDirectusRecentReview = recentReviewsHook();

  return (
    <>
      <SectionTitle title={section.heading} subtitle={section.subheading} align="center" />
      <Carousel height={420} width={730} maxVisibleSlides={2} hasArrows={false} gap dots lazy>
        {allDirectusRecentReview.map(({ node }) => (
          <div key={`review-${slugify(node.customer_name)}-${node.directusId}`}>
            <ReviewPane {...node} />
          </div>
        ))}
      </Carousel>
    </>
  );
};
