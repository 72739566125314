import React from 'react';
import LazyLoad from 'react-lazy-load';

import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import wordpressPostsHook from 'hooks/wordpress/wordpress-posts.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';

import { wpPosts, wpPosts__post, wpPosts__content, wpPosts__button } from './BlogPost.scss';

export default (): JSX.Element => {
  const blogPosts = wordpressPostsHook();
  const { node } = getFieldBySlug('homepage-blog-posts', sectionTitleHook());

  return (
    <>
      <SectionTitle title={node.heading} align="center" />
      <div className={wpPosts}>
        {blogPosts.map(({ node }) => (
          <div key={node.slug} className={wpPosts__post}>
            <LazyLoad>
              <img src={node.featured_media.medium} alt={node.title} loading="lazy" />
            </LazyLoad>
            <div className={wpPosts__content}>
              <h3 key={node.slug}>{node.title}</h3>
              <a href={node.link} className={wpPosts__button}>
                Read Post
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
